import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

var firebaseConfig = {
    apiKey: "AIzaSyDPzQ1BrL9sXeTL2QVeuPO8QnTYBfOVTLY",
    authDomain: "math-competition.firebaseapp.com",
    databaseURL: "https://math-competition.firebaseio.com",
    projectId: "math-competition",
    storageBucket: "math-competition.appspot.com",
    messagingSenderId: "369778710957",
    appId: "1:369778710957:web:1acb15f9624d9f9e15a924"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();