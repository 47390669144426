import React from "react";
import "./Rules.css";

const Rules = () => {
  return (
    <div className="rules">
      <div className="info">
        <h3>
          <span className="top-text">Регламент</span>
          <span className="bottom-text">на състезанието</span>
        </h3>
        <h5>
          <b>Състезанието</b> ще се проведе онлайн в един кръг на 10.XI.2020г.
          от 14.00 часа. Времетраенето на състезанието е <b>90 минути.</b> Брой задачи
          - <b>6 задачи със свободен отговор,</b> като необходим ще бъде само крайния
          резултат. Учениците ще използват създадения от тях акаунт в сайта по
          време на състезанието. След решаването на задача се въвежда само краен
          отговор <b>(без мерни единици).</b> Резултатът се проверява и ако отговора е
          верен ученикът ще получи комбинация от букви. При грешно въведен
          отговор, ще има възможност да се въведe нов отговор. Трябва да се
          съберат всички букви и да се изпише надслова на състезанието, състоящ
          се от 4 думи. Първата дума се състои от <b>10 букви,</b> втора дума – <b>8
          букви,</b> трета дума – <b>6 букви</b> и четвърта дума – <b>6 букви.</b> Полето за
          въвеждане на надслова ще бъде активно след 3 верни отговора на
          задачите. Всички задачи в състезанието са с практическа и
          професионална насоченост. <br /><b>Първо място получава ученикът, който изпише
          правилно надслова за най-кратко време.</b>
        </h5>
      </div>
    </div>
  );
};

export default Rules;
