import React, { useState, useEffect } from "react";
import "./Participants.css";
import { db } from "../fbConfig";
import M from "materialize-css";
import moment from "moment";
import "moment/locale/bg";

const Participants = ({ user, history }) => {
  const [userDetails, setUserDetails] = useState();
  const [users, setUsers] = useState([]);
  const [usersWithFinalAnswer, setUsersWithFinalAnswer] = useState([]);
  useEffect(() => {
    user &&
      db
        .collection("users")
        .doc(user.uid)
        .onSnapshot((snapshot) => setUserDetails(snapshot.data()));
  }, [user]);
  useEffect(() => {
    db.collection("users")
      .where("grade", "!=", "admin")
      .onSnapshot((snapshot) =>
        setUsers(snapshot.docs.map((doc) => ({ id: doc.id, user: doc.data() })))
      );
    db.collection("users")
      .orderBy("finalAnswerTimestamp", "asc")
      .onSnapshot((snapshot) =>
        setUsersWithFinalAnswer(
          snapshot.docs.map((doc) => ({ id: doc.id, user: doc.data() }))
        )
      );
  }, []);

  useEffect(() => {
    moment().locale("bg");
    M.Collapsible.init(document.querySelectorAll(".collapsible"));
  }, []);
  if (!user || (userDetails && userDetails.grade !== "admin"))
    history.push("/");
  return (
    <div className="row participants">
      <div className="col s12 m6">
        <div className="participants__header">
          <p>Регистрирани участници:</p>
        </div>
        <ul className="collection">
          {users &&
            users.map(({ user, id }) => (
              <li key={id} className="collection-item">
                <div>
                  <i className="material-icons">person_outline</i>
                  <span>
                    {user.firstName} {user.lastName} - <b>{user.grade} клас</b>
                  </span>
                </div>
                <span>{user.email}</span>
              </li>
            ))}
        </ul>
      </div>
      <div className="col s12 m6">
        <div className="participants__header">
          <p>Участници с изпратен отговор: </p>
        </div>
        <ul className="collapsible">
          {usersWithFinalAnswer &&
            usersWithFinalAnswer.map(
              ({ user, id }) =>
                user.grade !== "admin" && (
                  <li key={id}>
                    <div className="collapsible-header">
                      <div>
                        {user.firstName} {user.lastName} -{" "}
                        <b>{user.grade} клас</b>
                      </div>
                      {user.finalAnswerTimestamp && (
                        <p>
                          {moment(user.finalAnswerTimestamp.toDate()).format(
                            "LL, LTS"
                          )}
                        </p>
                      )}
                    </div>
                    {user.finalAnswerTimestamp && (
                      <div className="collapsible-body">
                        <h5>
                          <b>{user.finalAnswer}</b>
                        </h5>
                        <p>
                          {moment(user.finalAnswerTimestamp.toDate()).format(
                            "LL, LTS"
                          )}
                        </p>
                      </div>
                    )}
                  </li>
                )
            )}
        </ul>
      </div>
    </div>
  );
};

export default Participants;
