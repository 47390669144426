import React, { useState, useEffect, useRef } from "react";
import { db } from "../fbConfig";
import "./Certificate.css";
import GenerateDate from "./GenerateDate";
import Logo from "../image/logo.svg";
import BackgroundImage from "../image/bg.jpg";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";

const Certificate = ({ user }) => {
  const ref = useRef();
  const [userDetails, setUserDetails] = useState();
  useEffect(() => {
    user &&
      db
        .collection("users")
        .doc(user.uid)
        .onSnapshot((snapshot) => setUserDetails(snapshot.data()));
  }, [user]);

  return (
    <div className="certificate">
      <div className="certificate-container">
        <div className="certificate-app" ref={ref}>
          <div className="header">
            <img src={Logo} alt="logo" />
            <h5>Старопрестолна професионална гимназия по икономика</h5>
          </div>
          <div className="content">
            <div className="content__header">
              <h1>ГРАМОТА</h1>
            </div>
            <p>за участие в състезание</p>
            <p>
              <b>„Професионално ориентиране”</b>
            </p>
            <p>на</p>
            {userDetails && (
              <p className="name">
                {userDetails.firstName + " " + userDetails.lastName}
              </p>
            )}
          </div>
          <div className="footer">
            <GenerateDate />
          </div>
        </div>
      </div>
      <div className="certificate-buttons">
        <button
          className="pdfButton"
          onClick={() =>
            exportComponentAsJPEG(ref, { fileName: "certificate" })
          }
        >
          Изтегли изображение
        </button>
        <button
          className="pdfButton"
          onClick={() =>
            exportComponentAsPDF(ref, {
              fileName: "certificate",
              pdfOptions: {
                w: 210,
                h: 297,
                unit: "mm",
              },
            })
          }
        >
          Изтегли PDF
        </button>
      </div>
    </div>
  );
};

export default Certificate;
