function GenerateDate() {
    const getCurrentDate = () => {
      let months = [
        "Януари",
        "Февруари",
        "Март",
        "Април",
        "Май",
        "Юни",
        "Юли",
        "Август",
        "Септември",
        "Октомври",
        "Ноември",
        "Декември",
      ];
      let d = new Date();
      let month = d.getMonth();
      let date = d.getDate();
      let year = d.getFullYear();
      let now = `${date} ${months[month]} ${year} г.`;
      return now;
    };
    return (
      <p className="date">
        Дата: <span className="bold">{getCurrentDate()}</span>
      </p>
    );
  }
  
  export default GenerateDate;
  