import React, { useEffect, useState } from "react";
import M from "materialize-css";
import "./Nav.css";
import { Link } from "react-router-dom";
import LoggedInLinks from "./LoggedInLinks";
import LoggedOutLinks from "./LoggedOutLinks";
import { db } from "../fbConfig";

const Nav = ({ user }) => {
  const [userDetails, setUserDetails] = useState();
  useEffect(() => {
    M.Sidenav.init(document.querySelectorAll(".sidenav"));
  }, []);
  useEffect(() => {
    user &&
      db
        .collection("users")
        .doc(user.uid)
        .onSnapshot((snapshot) => setUserDetails(snapshot.data()));
  }, [user]);
  return (
    <header>
      <div className="nav">
        <div className="nav-wrapper">
          <Link to="/" className="brand-logo">
            <img src="logo.png" alt="logo" width="50" />
          </Link>
          {user && userDetails && (
            <div className="userDetails">
              Здравей,{" "}
              <span>
                <b>{userDetails.firstName + " " + userDetails.lastName}</b> !
              </span>
            </div>
          )}
          <ul className="hide-on-med-and-down">
            {user ? (
              <LoggedInLinks user={user} userDetails={userDetails} />
            ) : (
              <LoggedOutLinks user={user} />
            )}
          </ul>
        </div>
        <button data-target="mobile-demo" className="sidenav-trigger">
          <i className="material-icons">menu</i>
        </button>
      </div>
      <ul className="sidenav" id="mobile-demo">
        {user ? <LoggedInLinks user={user} /> : <LoggedOutLinks user={user} />}
      </ul>
    </header>
  );
};

export default Nav;
