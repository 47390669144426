import React, { useState, useEffect } from "react";
import "./Register.css";
import { auth, db } from "../fbConfig";
import M from "materialize-css";

const Register = (props) => {
  const [user, setUser] = useState({});
  const [users, setUsers] = useState("");
  useEffect(() => {
    db.collection("users").onSnapshot((snapshot) =>
      setUsers(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })))
    );
  }, []);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value });
  };
  const register = (e) => {
    e.preventDefault();
    auth
      .createUserWithEmailAndPassword(user.email, user.password)
      .then((res) => {
        db.collection("users").doc(res.user.uid).set({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          grade: user.grade,
        });
        M.toast({ html: "Успешна регистрация !", classes: "rounded green" });
        props.history.push("/");
      })
      .catch((err) =>
        M.toast({ html: `${err.message}`, classes: "rounded red" })
      );
  };
  return (
    <div className="register">
      <div className="register__spacing"></div>
      <div className="register__content">
        <div className="register__contentHeader">
          <h4>Регистрация на участник</h4>
        </div>
        <div className="register__contentBody">
          <form onSubmit={register}>
            <input
              type="text"
              placeholder="Име"
              className="validate"
              name="firstName"
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Фамилия"
              className="validate"
              name="lastName"
              onChange={handleChange}
            />
            <label htmlFor="grade">Клас:</label>
            <select defaultValue="grade" name="grade" onChange={handleChange}>
              <option value="grade" disabled>
                Изберете клас:
              </option>
              <option value="8а">8а</option>
              <option value="8б">8б</option>
              <option value="8в">8в</option>
              <option value="9а">9а</option>
              <option value="9б">9б</option>
              <option value="9в">9в</option>
              <option value="10а">10а</option>
              <option value="10б">10б</option>
              <option value="10в">10в</option>
              <option value="11а">11а</option>
              <option value="11б">11б</option>
              <option value="12а">12а</option>
              <option value="12б">12б</option>
            </select>
            <input
              type="email"
              placeholder="Email"
              className="validate"
              name="email"
              onChange={handleChange}
            />
            <input
              type="password"
              placeholder="Парола"
              className="validate"
              name="password"
              onChange={handleChange}
            />
            <button
              type="submit"
              className={`${
                user.firstName &&
                user.lastName &&
                user.grade &&
                user.email &&
                user.password
                  ? "submitBtn"
                  : "submitBtn disabledBtn"
              }`}
            >
              Регистрирай ме
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
