import React, { useState, useEffect } from "react";
import "./Ranking.css";
import { db } from "../fbConfig";

const Ranking = ({ user }) => {
  const [usersWithFinalAnswer, setUsersWithFinalAnswer] = useState([]);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    user &&
      db
        .collection("users")
        .orderBy("finalAnswerTimestamp", "asc")
        .onSnapshot((snapshot) =>
          setUsersWithFinalAnswer(
            snapshot.docs.map((doc) => ({ id: doc.id, user: doc.data() }))
          )
        );
    user &&
      db
        .collection("users")
        .where("grade", "!=", "admin")
        .onSnapshot((snapshot) =>
          snapshot.docs.map((doc) => setUsers(doc.data()))
        );
  }, [user]);

  return (
    <div className="ranking">
      {users && users.finalAnswer ? (
        <div className="ranking__content">
          <div className="ranking__header">
            <p>Класиране:</p>
          </div>
          <ul className="collection">
            {usersWithFinalAnswer &&
              usersWithFinalAnswer.map(
                ({ user, id }, index) =>
                  user.grade !== "admin" &&
                  user.finalAnswer === "Професията определя нашето бъдеще" && (
                    <li key={id} className="collection-item">
                      <div>
                        <p>
                          <b>{index + 1}.</b>
                        </p>
                        <span>
                          {user.firstName} {user.lastName} -{" "}
                          <b>{user.grade} клас</b>
                        </span>
                      </div>
                    </li>
                  )
              )}
          </ul>
        </div>
      ) : (
        <div className="ranking__content">
          <div className="ranking__header">
            <p>
              Класиране:{" "}
              <span className="red-text">няма класирани участници</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ranking;
