import React, { useState, useEffect } from "react";
import "./Answer.css";
import { db } from "../fbConfig";
import firebase from "firebase";
import M from "materialize-css";

const Answer = ({ user }) => {
  const [maxInputLength, setMaxInputLength] = useState(33);
  const [input, setInput] = useState("");
  const [finalAnswer, setFinalAnswer] = useState("");
  const [countCorrect, setCountCorrect] = useState([""]);

  useEffect(() => {
    db.collection("users")
      .doc(user.uid)
      .onSnapshot((snapshot) => setFinalAnswer(snapshot.data().finalAnswer));
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    window.confirm("Сигурни ли сте, че желаете да изпратите вашия отговор ?") &&
      db
        .collection("users")
        .doc(user.uid)
        .update({
          finalAnswer: input,
          finalAnswerTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          M.toast({
            html: "Отговорът е изпратен успешно !",
            classes: "rounded green",
          });
        });
  };
  useEffect(() => {
    db.collection("users")
      .doc(user.uid)
      .collection("userAssignments")
      .onSnapshot((snapshot) =>
        setCountCorrect(
          snapshot.docs.map((doc) => doc.data().isAnswered === "correct")
        )
      );
  }, [user]);
  return (
    <>
      {!finalAnswer && countCorrect.filter(Boolean).length >= 3 ? (
        <div className="finalAnswer">
          <form className="userInput" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Въведете вярно получените думи..."
              onChange={(e) => setInput(e.target.value)}
              maxLength={maxInputLength}
            />
            <button type="submit">
              Изпрати<i className="material-icons right">send</i>
            </button>
          </form>
          <span className="character-counter">
            {input.length} / {maxInputLength}
          </span>
        </div>
      ) : (
        finalAnswer && (
          <div className="finalAnswer">
            <h5>
              <i>Ориентирай своя път чрез талант и професионален избор !</i>
            </h5>
          </div>
        )
      )}
    </>
  );
};

export default Answer;
