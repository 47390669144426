import React, { useState, useEffect } from "react";
import "./App.css";
import Home from "./components/Home";
import Nav from "./components/Nav";
import { Switch, Route, Redirect } from "react-router-dom";
import Register from "./components/Register";
import Login from "./components/Login";
import Assignments from "./components/Assignments";
import { auth } from "./fbConfig";
import Rules from "./components/Rules";
import Participants from "./components/Participants";
import Ranking from "./components/Ranking";
import Certificate from "./components/Certificate";

function App() {
  const [user, setUser] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser();
      }
    });
  }, [user]);
  return (
    <>
      <Nav user={user} />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Home {...props} user={user} />}
        />
        <Route path="/регламент" component={Rules} />
        <Route path="/регистрация" component={Register}>
          {user && <Redirect to="/" />}
        </Route>
        <Route path="/вход" component={Login}>
          {user && <Redirect to="/" />}
        </Route>
        <Route
          path="/задачи"
          render={(props) => <Assignments {...props} user={user} />}
        >
          {!user && <Redirect to="/регистрация" />}
        </Route>
        <Route
          path="/класиране"
          render={(props) => <Ranking {...props} user={user} />}
        >
          {!user && <Redirect to="/регистрация" />}
        </Route>
        <Route
          exact
          path="/участници"
          render={(props) => <Participants {...props} user={user} />}
        />
        <Route
          path="/сертификат"
          render={(props) => <Certificate {...props} user={user} />}
        >
          {!user && <Redirect to="/" />}
        </Route>
      </Switch>
    </>
  );
}

export default App;
