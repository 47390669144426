import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";

const Home = ({ user }) => {
  return (
    <main>
      <div className="info">
        <h3>
          <span className="top-text">Професионално</span>
          <span className="bottom-text">ориентиране</span>
        </h3>
        <h5>
          <b>Целите</b> на това състезание са насочени към своевременното
          откриване на професионалните интереси и заложби на учениците, както и
          уменията им за прилагането на знанията им в практически задачи и в
          необичайни ситуации.
        </h5>
      </div>
      <div className="buttons">
        {!user ? (
          <Link to="регистрация">
            <button className="registerButton">Запиши се за участие</button>
          </Link>
        ) : (
          <Link to="задачи">
            <button className="registerButton">Решаване на задачи</button>
          </Link>
        )}
        <Link to="регламент">
          <button className="termsButton">Регламент</button>
        </Link>
      </div>
    </main>
  );
};

export default Home;
