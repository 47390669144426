import React, { useEffect, useState } from "react";
import "./Assignments.css";
import M from "materialize-css";
import Answer from "./Answer";
import { db } from "../fbConfig";

const Assignments = ({ user }) => {
  useEffect(() => {
    M.Tabs.init(document.querySelectorAll(".tabs"));
  }, []);
  const [assignments, setAssignments] = useState([]);
  const [assignment, setAssignment] = useState("");
  const [correct, setCorrect] = useState("");
  const [wrong, setWrong] = useState("");
  const [question, setQuestion] = useState("Първа задача");
  const [input, setInput] = useState("");
  const [userAssignment, setUserAssignment] = useState([]);
  const [userAssignments, setUserAssignments] = useState([]);
  useEffect(() => {
    db.collection("assignments")
      .orderBy("id")
      .onSnapshot((snapshot) =>
        setAssignments(snapshot.docs.map((doc) => doc.data()))
      );
  }, []);
  useEffect(() => {
    db.collection("assignments")
      .where("title", "==", question)
      .onSnapshot((snapshot) =>
        snapshot.docs.map((doc) =>
          setAssignment({ id: doc.id, data: doc.data() })
        )
      );
  }, [question]);
  useEffect(() => {
    db.collection("users")
      .doc(user.uid)
      .collection("userAssignments")
      .where("title", "==", question)
      .onSnapshot((snapshot) =>
        snapshot.docs.map((doc) => setUserAssignment(doc.data()))
      );
  }, [user, question]);
  useEffect(() => {
    db.collection("users")
      .doc(user.uid)
      .collection("userAssignments")
      .onSnapshot((snapshot) =>
        setUserAssignments(snapshot.docs.map((doc) => doc.data()))
      );
  }, [user]);
  const getAnswer = (e) => {
    e.preventDefault();
    if (assignment.data.answer === input) {
      db.collection("users")
        .doc(user.uid)
        .collection("userAssignments")
        .doc(assignment.id)
        .set({ ...assignment.data, userInput: input, isAnswered: "correct" });
      setWrong("");
    } else {
      db.collection("users")
        .doc(user.uid)
        .collection("userAssignments")
        .doc(assignment.id)
        .set({ ...assignment.data, userInput: input, isAnswered: "wrong" });
      setWrong(true);
    }
  };
  const selectQuestion = (e) => {
    setQuestion(e.target.value);
    setInput("");
    setWrong("");
  };
  return (
    <div className="assignments">
      <p>
        Изберете задачата, която искате да решите и запишете правилния отговор:
      </p>
      {!assignment ? (
        <div>
          <p>Задачите няма да бъдат активни до началото на състезанието.</p>
        </div>
      ) : (
        <div className="assignment__body">
          <select onChange={selectQuestion}>
            {assignments &&
              assignments.map((assignment, index) => (
                <option value={assignment.title} key={index}>
                  {assignment.title}
                </option>
              ))}
          </select>
          <div className="assignment__content">
            {assignments &&
              assignments.map(
                (assignment, index) =>
                  assignment.title === question && (
                    <p key={index}>{assignment.content}</p>
                  )
              )}
          </div>
          <form onSubmit={getAnswer} className="answer">
            <p>Вашият отговор: </p>
            <input
              type="text"
              className={`browser-default`}
              onChange={(e) => setInput(e.target.value)}
              value={input}
            />
            <button type="submit">
              Изпрати решение
              <i className="material-icons right">send</i>
            </button>
          </form>
          <div className="check-answer">
            {userAssignment &&
              userAssignment.title === question &&
              userAssignment.isAnswered === "correct" && (
                <div className="correct-answer">
                  <p>Верен отговор !</p>
                  <span>Букви: {userAssignment.letters}</span>
                </div>
              )}
            {wrong && <p className="wrong-answer">Грешен отговор !</p>}
          </div>
        </div>
      )}

      <Answer userAssignments={userAssignments} user={user} />
    </div>
  );
};

export default Assignments;
