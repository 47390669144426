import React from "react";
import { Link, NavLink } from "react-router-dom";
import { auth } from "../fbConfig";

const LoggedInLinks = ({ userDetails }) => {
  return (
    <>
      <li>
        <NavLink exact to="/">
          Начало
        </NavLink>
      </li>
      <li>
        <NavLink to="/регламент">Регламент</NavLink>
      </li>
      <li>
        <NavLink to="/задачи">Задачи</NavLink>
      </li>
      {userDetails && userDetails.grade === "admin" && (
        <li>
          <NavLink to="/участници">Участници</NavLink>
        </li>
      )}
      <li>
        <NavLink to="/класиране">Класиране</NavLink>
      </li>
      {userDetails && userDetails.finalAnswer === "Професията определя нашето бъдеще" ? (
        <li>
          <NavLink to="/сертификат">Сертификат</NavLink>
        </li>
      ) : null}
      <li>
        <Link to="/" onClick={() => auth.signOut()}>
          Изход
        </Link>
      </li>
    </>
  );
};

export default LoggedInLinks;
