import React from "react";
import { NavLink } from "react-router-dom";

const LoggedOutLinks = () => {
  return (
    <>
      <li>
        <NavLink exact to="/">
          Начало
        </NavLink>
      </li>
      <li>
        <NavLink to="/регламент">Регламент</NavLink>
      </li>
      <li>
        <NavLink to="/регистрация">Регистрация</NavLink>
      </li>
      <li>
        <NavLink to="/вход">Вход</NavLink>
      </li>
    </>
  );
};

export default LoggedOutLinks;
