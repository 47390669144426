import React, { useState } from "react";
import "./Login.css";
import { auth } from "../fbConfig";
import M from "materialize-css";

const Login = (props) => {
  const [user, setUser] = useState(null);
  const login = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(user.email, user.password)
      .then((res) => {
        M.toast({
          html: "Успешно влязохте в акаунта !",
          classes: "rounded green",
        });
        props.history.push("/");
      })
      .catch((err) =>
        M.toast({ html: `${err.message}`, classes: "rounded red" })
      );
  };
  return (
    <div className="login">
      <div className="login__spacing"></div>
      <div className="login__content">
        <div className="login__contentHeader">
          <h4>Вход</h4>
        </div>
        <div className="login__contentBody">
          <form onSubmit={login}>
            <input
              type="email"
              placeholder="Email"
              className="validate"
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            <input
              type="password"
              placeholder="Парола"
              className="validate"
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
            <button type="submit" className="submitBtn">
              Влез
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
